/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Fragment } from 'react'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'



import Spinner from '../components/spinner/index'
import ApiRx from '../service/ApiRx'
import DialogViewImage from '../components/rx/DialogViewImage'


const ImageRx = () => {


  const [loading, setLoading] = useState(false)
  const [data_, setData] = useState(null)
  const [errorData, setErrorData] = useState(false)
  const [showViewImage, setShowViewImage] = useState(false)
  const [fileSelected, setFileSelected] = useState(false)

  useEffect(() => {
    let search = window.location.search
    let params = new URLSearchParams(search)
    let id = params.get('id')

    fetchImages(id)
  }, [])

  const fetchImages = id => {
    setLoading(true)

    ApiRx.detailImage({ id: id }, response => {
      setLoading(false)
      if (response.result != null) {
        if (response.result.codigo === 1) {
          if (response.result.content !== null) {
            setData(response.result.content)
          } else {
            setErrorData(true)
          }
        } else {
          setErrorData(true)
        }
      } else {
        setErrorData(true)
      }
    })
  }

  const HeaderDate = props => {
    const { title, body } = props

    return (
      <Grid container xs justifyContent='left'>
        <Grid item>
          <Typography variant='h7' gutterBottom component='div' sx={{ fontWeight: 400, color: 'white', fontSize: 14 }}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant='h7'
            gutterBottom
            component='div'
            sx={{ fontWeight: 500, ml: 1, color: 'white', fontSize: 14 }}
          >
            {body}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const BottomData = () => {
    return <Grid container sx={12}   
                 direction={{xs:"column",md:"row"}}
                 justifyContent="space-between"
                 alignItems="center"
          pt="2" p={2}>
     <Grid item >

     <Typography
              variant='variant'
              gutterBottom
              component='div'
              sx={{ fontWeight: 600, textAlign:{xs:"center",md:"left"}, color: 'black',mt:2 }}
            >
              {data_ != null ? data_?.sucursal.nombre : ''}
      </Typography>
        <Typography
          variant='variant'
          gutterBottom
          component='div'
          sx={{ fontWeight: 300, textAlign:{xs:"center",md:"left"}, color: 'black', fontSize: 14 }}
        >
          {data_ != null ? data_?.sucursal?.direccion : ''}
      </Typography>
     </Grid>
     <Grid item >
     {data_ != null && data_?.sucursal?.contactos?.length > 0 ? (
            data_?.sucursal.contactos.map((e, index) => (
                <Typography
                variant='variant'
                gutterBottom
                component='div'
                sx={{ fontWeight: 300, textAlign: {xs:"center",md:"left"}, color: 'black', fontSize: 14 }}
              >
                {e.tipo_contacto}: <span sx={{fontWeight: 500}}>{e.descripcion}</span>
              </Typography>
            
            ))
          ) : (
            <Grid />
          )}
      </Grid>
    </Grid>
  }

  const DataUser = () =>{

    return <Grid item xs={12} mt={3}>
        
        <Grid item xs={12}>
          <Divider sx={{ m: '0 !important', background: 'gray' }} />
        </Grid>
        <Grid item spacing={0} sx={{ml:{xs:2,sm:2,md:5},mt:2,mb:0}}  >
          <Grid container>
            <Grid item xs={12} md={6}>
            <img alt='di_logo_header' src={'/images/isologos/logo_planin_white.png'} height={30} />

            </Grid>
            <Grid item xs={12} md={6} mt={{sm:2,xs:2,md:0}}>
            <HeaderDate
                title='Paciente: '
                body={data_ != null ? data_?.paciente?.nombres + ' ' + data_?.paciente?.apellidos : ''}
              />
              <HeaderDate title='Edad: ' body={data_ != null ? data_?.paciente?.edad : ''} />
            </Grid>
          </Grid>
        </Grid>
    </Grid>
  }

  const ItemImage = props => {
    const { row } = props

    return (
      <Grid item xs={12} sx={{ background: '#000' }} style={{ justifyContent:"center",justifyItems:"center"}}>
  
       <DataUser row={row} />
        <Grid xs={12} mt={3} style={{ flexDirection: 'column', alignItems: 'center'}}>
          <img
           onClick={() => {
            // setFileSelected(row)
            // setShowViewImage(true)
          }}
            src={`${row.enlace_archivo}`}
            alt='display image'
            style={{ margin: '0em 1em 1em 0em',objectFit: 'cover', height: 'auto',  maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12} pb={1.5} pl={{xs: 2,md:5}} style={{}}>
              <HeaderDate title='Fecha creación: ' body={row.creacion}/>
        </Grid>
      </Grid>
    )
  }

  const NonData = () => {
    return (
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            <Alert severity='error'>
              <AlertTitle>Ups!!</AlertTitle>
              No hay vista previa disponible.
            </Alert>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner sx={{ height: '100%' }} />
      ) : errorData ? (
        <NonData />
      ) : (
        <Grid item xs={12} sx={{ px: { sm: 0, xs: 0, md: 10 }, py: { sm: 0, xs: 0, md: 10 } }}>
          <Card>
            <CardContent>
              <Grid item xs={12}>
                <Grid item mb={4}>
                  <Typography variant='h6' gutterBottom component='div' sx={{ fontWeight: 600 }}>
                    Orden digital # {data_ != null ? data_.OrdenId : ''}
                  </Typography>
                  <Typography variant='h6' gutterBottom component='div' sx={{ fontWeight: 700 }}>
                    {data_ != null ? data_.servicio.nombre : ''}
                  </Typography>
                </Grid>
                {data_ != null && data_.imagenes?.length > 0 ? (
                  data_.imagenes?.map((e, index) => (
                    <ItemImage
                      key={index}
                      row={e}
                      image='https://drive.google.com/uc?export=view&id=1i2e2SyNDWFvVLIU7SSEMYZxvgFIJTu-5'
                    />
                  ))
                ) : (
                  <NonData />
                )}
                 
<BottomData/>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
       {showViewImage && (
  <DialogViewImage show={showViewImage} setShowViewImage={setShowViewImage} file={fileSelected} />
)}      
    </Fragment>
  )
}

export default ImageRx
